import Info from "../components/project_details/Info";
import ProjectInfo from "../components/project_details/ProjectHeader";
import PageContainer from "./PageContainer";
import { useEffect } from "react";

const MoveYourBody = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const project = {
    headline:'Move your Body',
    info: 'Move your Body ist ein kostenloses Sportangebot für ALLE Kinder und Jugendlichen aus dem Bezirk Hartberg-Fürstenfeld. Unser Angebot bezieht sich auf bekannte Sportarten, sowie auch darauf neue Sportarten vorzustellen und auszuprobieren.',
    image: 'move_your_body.png'
  }
  return(
    <PageContainer>
      <div className="flex flex-col gap-32">
        <ProjectInfo project={project}/>
        <div className="flex flex-col w-full gap-16">
          <div className="text-lg lg:text-3xl xl:text-5xl font-semibold">Was bieten wir?</div>
            <div className="grid grid-cols-2 grid-rows-2 gap-x-8 gap-y-24">
              <Info text={"Gegenseitige Motivation, Wissensaneingung und Erweiterung der Hobbies im Sportbereich."}/>
              <Info text={"Aufklärung über gute Ernährung, verschiedene Nahrungsmittel und deren Gesundheitswert."}/>
              <div className="flex w-full flex-col gap-12 pr-12">
                <div className="text-lg lg:text-3xl font-semibold">Wo und wann findest du uns?</div>
                <div className="text-sm lg:text-lg xl:text-2xl">Standort : Gartengasse 6, 8230 Hartberg<br />Bei Schulbetrieb - Dienstags von 15 Uhr bis 17 Uhr</div>
              </div>
              <div className="flex w-full flex-col gap-12 pr-12">
                <div className="w-20 h-10 bg-green rounded-xl image_style"></div>
                <div className="text-sm lg:text-lg xl:text-2xl"></div>
              </div>
            </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default MoveYourBody;