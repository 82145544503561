import { useEffect } from "react";
import { Link } from "react-router-dom";

const Project = ({project, isLeft}) => {

  return(
    <div className={`w-full h-min flex justify-center ${isLeft ? '' : 'flex-row-reverse'}`}>
      <div className={`w-1/2 p-4 flex xl:p-20 ${isLeft ? '' : 'justify-end'}`}>
        <img className="rounded-3xl object-cover big_image_style" src={'./'+project.img}/>
      </div>

      <div className="p-4 xl:p-20 w-1/2 flex flex-col gap-4 lg:gap-8 xl:gap-12">
        <div className="text-lg lg:text-3xl xl:text-5xl font-semibold">
          {project.title}
        </div>
        <div className="text-sm lg:text-lg xl:text-2xl">
          {project.text}
        </div>
        <Link to={project.link}>
          <div className="text-md xl:text-2xl cursor-pointer bg-yellow text-center flex items-center rounded-xl border-2 border-black p-2 xl:p-3 w-min text-nowrap">
            Zur Website
          </div>
        </Link>
      </div>
      <div>
      </div>
    </div>
  )
}

export default Project;