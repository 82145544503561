import React from "react";
import Navbar from "./components/Navbar";
import Welcome from "./components/Welcome";
import { useState, useEffect } from 'react';
import Footer from "./components/Footer";
import ProHeader from "./components/Projects_Header";
import Projects from "./components/projects/Projects";
import MainPage from "./pages/MainPage";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Jeki from "./pages/JEKI";
import MoveYourBody from "./pages/MoveYourBody";
import HotPot from "./pages/HotPot";

const router = createBrowserRouter([
  {
      path: '/',
      element: <MainPage />
  },
  {
    path: '/about_us',
    element: <AboutUs />
  },
  {
    path: '/jeki',
    element: <Jeki />
  },
  {
    path: '/move_your_body',
    element: <MoveYourBody />
  },
  {
    path: '/hot_pot',
    element: <HotPot />
  },
  
])

const App = () => {
  
  return (
      <RouterProvider router={router} />
  );
}

export default App;
