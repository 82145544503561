import Info from "../components/project_details/Info";
import ProjectInfo from "../components/project_details/ProjectHeader";
import PageContainer from "./PageContainer";
import { useEffect } from "react";

const Jeki = ({info}) => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const project = {
    headline:'Jugend Eltern Kind Info',
    info: 'Jugend.Eltern.Kind.Informationen, kurz JEKI genannt, bietet in Gemeinden im Bezirk Hartberg-Fürstenfeld eine lokale, präventive und anonyme Informationsdrehscheibe an.',
    image: 'jeki_bus.png'
  }
  return(
    <PageContainer>
      <div className="flex flex-col gap-32">
        <ProjectInfo project={project}/>
        <div className="flex flex-col w-full gap-16">
          <div className="text-lg lg:text-3xl xl:text-5xl font-semibold">Rund um die Themen</div>
          <div className="grid grid-cols-2 grid-rows-2 gap-x-8 gap-y-24">
            <Info text={"Hier Text für Beistand"}/>
            <Info text={"Beitrag zur individuellen Weiterentwicklung eines einzelnen."}/>
            <Info text={"Sinnvolle und soziale Gestaltung der Freizeit durch motorisch-fördernde Beschäftigungen."}/>
            <Info text={"Hier Text für Schule/Aubsildung"}/>
          </div>
          <div className="flex flex-col w-3/5 gap-4 pt-20">
            <div className="text-lg lg:text-3xl xl:text-5xl font-semibold">Was bieten wir?</div>
            <div className="text-sm lg:text-lg xl:text-2xl">JEKI, wie wir auch unseren Bus nennen, ist mit verschiedensten 
                                                            Bastel- und Outdoormaterialien ausgestattet, mit denen sich Kinder 
                                                            und Jugendliche auseinandersetzen dürfen, während das JEKI - Team 
                                                            mitden Erwachsenen ins Gespräch kommen kann. Wir sind bestens über Leistungen im Bezirk informiert und haben bei Bedarf Folder und Informationsmaterialien zu den verschiedensten Themen dabei.
            </div>
            <div/>
            <div className="text-sm lg:text-lg xl:text-2xl">Bei uns ist jede/r herzlich willkommen!</div>
          </div>
          <div className="h-40"></div>
        </div>
      </div>
    </PageContainer>
  )
}

export default Jeki;