import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import Projects from "../components/projects/Projects"
import ProHeader from "../components/Projects_Header"
import Welcome from "../components/Welcome"

const MainPage = () => {
  return(
    <>
      <div className=" flex-col w-full hidden p-0 lg:flex items-center">
        <div className="pt-8 w-5/6 xl:min-h-screen m-0 flex flex-col justify-between lg:pt-14 xl:pt-24 gap-4 lg:gap-12 xl:gap-0">
          <Navbar />
          <Welcome />
          <Footer />
        </div>
        <div className="w-5/6 py-12 xl:py-24">
          <ProHeader />
          <Projects />
        </div>
      <div className="w-full bg-yellow flex items-center gap-10 p-4 flex-col">
        <div></div>
        <div className="text-lg lg:text-3xl xl:text-5xl font-semibold">Arge</div>
        <div className="text-sm lg:text-lg xl:text-2xl">© ARGE 2024</div>
        <div className="text-sm lg:text-lg xl:text-2xl">Datenschutz - Cookies - Impressum</div>
      </div>
      </div>
      <div className="font-semibold text-2xl w-full h-screen flex items-center justify-center">HomePage in arbeit!</div>
    </>
  )
}

export default MainPage