import ProjectInfo from "../components/project_details/ProjectHeader";
import PageContainer from "./PageContainer";
import { useEffect } from "react";

const HotPot = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const project = {
    headline:'Hot Pot',
    info: 'Alle sind in diesem Projekt willkommen, die einen freudvollen Zugang zu gesunder Ernährung bekommen wollen, die bunt, lecker und unkompliziert ist. Dabei können auch neue soziale Kontakte und Freundschaften geknüpft werden.',
    image: 'hot_pot.png'
  }
  return(
    <PageContainer>
      <ProjectInfo project={project}/>
    </PageContainer>
  )
}

export default HotPot;