
const ProjectInfo = ({project}) => {
  return(
    <div className="w-full">
       <div className="pt-28">
        <div className="flex justify-between items-center">
          <div className="flex flex-col w-2/5 gap-16">
            <div className="text-5xl font-semibold">{project.headline}</div>
            <div className="text-2xl font-medium">{project.info}</div>
          </div>
          <div className="flex justify-center items-center">
            <img className="big_image_style rounded-3xl w-full object-cover" src={'./' + project.image}/>
          </div>
        </div>
       </div>
    </div>
  )
}

export default ProjectInfo;