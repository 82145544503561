import money from '../assets/Group 12.svg'

const Footer = () => {
  return(
    <div className="flex w-full pb-10">
      <div className="w-1/2 flex flex-col gap-6">
        {/*<img className='w-16 h-8 xl:w-40 xl:h-40' src={green}/>*/}
        <div className="bg-green rounded-xl image_style relative w-10 h-5 lg:w-16 lg:h-8 xl:w-24 xl:h-12">
          {/*<img className='absolute w-full -top-3 shadow-xl' src={money} />*/}
        </div>
        <div className="text-sm lg:text-lg font-medium xl:text-2xl">
        Wir bieten <span className="font-semibold">kostenfreie</span> und <span className="font-semibold">öffentlich zugängliche</span> Gruppenaktivitäten.
        </div>
      </div>
      <div className="w-1/2 flex flex-col gap-6 pl-20">
        <div className="bg-blue rounded-xl image_style w-10 h-5 lg:w-16 lg:h-8 xl:w-24 xl:h-12"/>
        <div className="text-sm lg:text-lg font-medium xl:text-2xl">Unsere Projekte entstehen aus <span className="font-semibold">persönlichem Engagement</span> und viel <span className="font-semibold">Herz</span> unserer KollegInnen!</div>
      </div>
    </div>
  )
}

export default Footer;