const ProHeader = () => {

  const text = "Unsere Projekte entstehen aus persönlichem Engagement und viel Herz unserer KollegInnen! Als Kind, oder Jugendliche/r (bis 18) hast du die Möglichkeit in einer Gruppe an unterschiedlichsten Aktivitäten teilzunehmen. Gemeinsam wird etwas gestaltet, werden Aktivitäten unternommen, wird gelacht und werden Hürden genommen. Deine Ideen und Wünsche werden gehört und werden innerhalb einer geregelten Struktur und klaren Abläufen integriert. Für Sie als Erwachsener bieten sich durch unsere Angebote zahlreiche Möglichkeiten zur Weiterbildung und für Austausch, um Erfahrungen miteinander zu teilen und zu lernen. Gruppenerlebnisse verschaffen zusätzliche Einblicke in uns selbst und andere und erweitern unseren Horizont."

  return(
    <div className="w-full flex items-center flex-col gap-8 pt-8" id='projects'>
      <div className="font-semibold text-lg md:text-xl lg:text-3xl xl:text-5xl">Unsere Projekte</div>
      <div className="text-sm lg:text-lg xl:text-2xl text-center">{text}</div>
    </div>
  )
}

export default ProHeader;