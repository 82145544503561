

const Info = ({icon, text}) => {
  return(
    <div className="flex w-full flex-col gap-12 pr-12">
      <div className="w-20 h-10 bg-green rounded-xl image_style"></div>
      <div className="text-sm lg:text-lg xl:text-2xl">{text}</div>
    </div>
  )
}

export default Info;