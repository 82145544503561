
const Welcome = () => {
  return(
    <div className="flex flex-grow">
      <div className="flex flex-col h-full w-1/2 gap-8 xl:gap-16 mt-8 xl:pt-20">
        <div className="font-semibold text-lg lg:text-3xl xl:text-5xl xl:leading-relaxed">Willkommen bei der <span className="bg-yellow rounded-xl px-2 py-0">ARGE</span> Hartberg-Fürstenfeld!</div>
        <div className="font-medium text-sm lg:text-lg xl:text-2xl w-4/5 text-justify pb-12">Die ARGE der Flexible Hilfen Hartberg-Fürstenfeld begleitet Kinder, Jugendliche und Familien bei der Umsetzung ihrer individuellen Ziele. </div>
      </div>
      <div className="w-1/2 min-h-full">
        <div className="pl-8 lg:pl-20 flex relative flex-col justify-center h-full">
          <img className="rounded-2xl w-5/6 object-cover big_image_style" src="./welcome.png"/>
        </div>
      </div>
    </div>
  )
}

export default Welcome